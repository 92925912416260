.react-datepicker {
    z-index: 9999;
    background-color: $color-background !important;
    font-family: 'Nunito', sans-serif !important;
    padding: 10px 26px 30px;
    border-radius: 2px;
    border-color: #e0e0e0 !important;

    .react-datepicker__month-container {
        margin: 0 20px;
    }

    .react-datepicker__header {
        background-color: transparent;
        border-radius: 0px;
        border-bottom: 1px solid #E0E4E7;
        margin-bottom: -4px;

        .react-datepicker__current-month {
            display: none !important;
        }

        .react-datepicker__day-names {
            border-bottom: 0px solid #e6eaed;
            height: 22px;
            overflow: hidden;
            margin-top: 10px;

            .react-datepicker__day-name {
                color: $color-grey2;
                font-size: 11px;
                font-family: 'Quicksand-Bold', sans-serif;
                text-transform: uppercase;
                line-height: 1.2rem;
                font-weight: 700;
                margin-top: -18px;

                &:first-child {
                    text-transform: lowercase;
                    padding-right: 8px;
                }
            }
        }
    }

    .react-datepicker__header__dropdown.react-datepicker__header__dropdown--select {
        select {
            border: none;
            background-color: transparent;
            font-size: 16px;
            font-family: 'Quicksand-Bold', sans-serif;
            letter-spacing: 0.4px;
            font-weight: 400;
            width: 100px;
            margin: 10px 20px;
        }
    }

    .react-datepicker__month-read-view--selected-month,
    .react-datepicker__year-read-view--selected-year {
        font-size: 16px;
        font-family: 'Quicksand-Bold', sans-serif;
        letter-spacing: 0.4px;
        font-weight: 400;
        width: 100px;
    }

    .react-datepicker__month-read-view--down-arrow, .react-datepicker__year-read-view--down-arrow {
        left: 4px!important;
        top: 7px!important;
        margin-left: 30px;
    }

    .react-datepicker__month-read-view--down-arrow, .react-datepicker__month-read-view--down-arrow:before, .react-datepicker__tether-element-attached-bottom .react-datepicker__triangle, .react-datepicker__tether-element-attached-bottom .react-datepicker__triangle:before, .react-datepicker__tether-element-attached-top .react-datepicker__triangle, .react-datepicker__tether-element-attached-top .react-datepicker__triangle:before, .react-datepicker__year-read-view--down-arrow, .react-datepicker__year-read-view--down-arrow:before {
        border-width: 4px !important;
    }

    .react-datepicker__month-read-view--down-arrow:before, .react-datepicker__tether-element-attached-bottom .react-datepicker__triangle:before, .react-datepicker__tether-element-attached-top .react-datepicker__triangle:before, .react-datepicker__year-read-view--down-arrow:before {
        left: -4px !important;
    }

    .react-datepicker__month-dropdown {
        left: 10% !important;
    }

    .react-datepicker__year-dropdown {
        left: 50% !important;
    }

    .react-datepicker__month-dropdown,
    .react-datepicker__year-dropdown {
        font-family: Quicksand, sans-serif;
        font-size: 15px;

        .react-datepicker__month-option,
        .react-datepicker__year-option {
            line-height: 24px;
        }
    }

    .react-datepicker__navigation--previous, .react-datepicker__navigation--next {
        border-right-color: transparent;
        border-left-color: transparent;
        display: inline-block;
        height: 100%;
        text-align: center;
        width: 30px;

        &:after {
            width: 8px;
            height: 8px;
            content: '';
            border: 2px solid #000;
            position: absolute;
            top: calc(50% - 5px);
            display: block;
            cursor: pointer;
            border-top: 0;
        }

        &:hover {
            &:after {
                border-color: $color-main1 !important;
            }
        }
    }

    .react-datepicker__navigation--previous {
        left: 24px;
        top: 0px;
        overflow: visible;

        &:hover {
            border-right-color: transparent;
            &:after {
                border-color: #000;
            }
        }

        &:after {
            border-right: 0;
            transform: rotate(45deg);
        }
    }

    .react-datepicker__navigation--next {
        right: 24px;
        top: 0px;
        overflow: visible;

        &:hover {
            border-left-color: transparent;
            &:after {
                border-color: #000;
            }
        }

        &:after {
            border-left: 0;
            transform: rotate(-45deg);
            right: 0px;
        }
    }

    .react-datepicker__month {
        margin-top: 0px;

        .react-datepicker__week {
            height: 27px;
        }
    }

    .react-datepicker__day {
        font-size: 14px;
        font-weight: 300;
        &:hover {
            border-radius: 50%;
            background-color: #d4dce6;
        }
    }

    .react-datepicker__day--weekend + .react-datepicker__day--weekend {
        color: $color-main1;
    }

    .react-datepicker__day--outside-month {
        color: #b0b6b9;
    }

    .react-datepicker__day--outside-month.react-datepicker__day--weekend + .react-datepicker__day--outside-month.react-datepicker__day--weekend {
        color: #ebcb8a;
    }

    .react-datepicker__day--selected {
        border-radius: 50%;
        background-color: #000;
    }

    .react-datepicker__day--today {
        border-radius: 50%;
        background-color: $color-main1;
        color: #fff !important;
    }

    .react-datepicker__week-number {
        color: #b0b6b9;
        border-right: 1px solid #E0E4E7;
        padding-right: 15px;
        padding-top: 5px;
    }
}

.react-datepicker__triangle {
    border-top-color: #f2f5f7 !important;

    &:before {
        border-top-color: #e0e0e0 !important;
        border-bottom-color: #e0e0e0 !important;
    }
}

.react-datepicker-popper[data-placement$=end] {
    .react-datepicker__triangle {
        left: 85% !important;
    }
}
