.ReactFileUploader {
  .CropperModalBackground {
    background-color: rgba(0,0,0, 0.4) !important;
    width: 100vw;
    height: 100vh;
    position: fixed;
    left: 0;
    top: 0;
    z-index: 1000000
  }

  .Label {
    cursor: pointer;
    display: inline-block;

    .UploadInput {
      display: none;
    }

    &.Default {
      padding: 10px 20px;
      border: 2px solid #3399ff;
      border-radius: 5px;
      transition: .2s;
      font-size: 14px;

      &:hover {
        background-color: dodgerblue;
        color: #fff;
      }

      &:focus {
        outline: 0;
      }
    }
  }
}

.FileUploaderCustomButton {
  color: #E59907;
  border: none;
  text-decoration: none;
  font-family: 'Nunito', sans-serif;
  font-weight: 400;
  font-size: 14px;
  width: 100%;
  position: absolute;
  display: flex;
  top: 200px;
  justify-content: center;
  max-width: 100px;
}

.DropzonePlaceholder {
  width: 100%;
  height: 70px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #E59907;
  color: #E59907;
  border-radius: 0px;
  background-color: #FFFCF7;

  h5 {
    font-weight: 400;
    font-size: 15px;
  }
}
