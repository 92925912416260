.BonusCode {
  font-family: 'Quicksand', sans-serif;
  font-weight: 700;
  font-size: 14px;
  line-height: 100%;
  color: $color-grey3;
  min-height: 88px;
  margin-top: 30px;
  
  .notification,
  .hint {
    display: block;
    text-align: center;
  }

  .hint {
    color: $color-green;
  }

  .BonusCodeInput {
    padding-bottom: 5px;
    margin-top: 20px;
    margin-bottom: 12px;
    border-bottom: 1px solid #C0BFC0;
    width: 100%;
    font-family: 'Quicksand', sans-serif;
    font-weight: 700;
    color: $color-grey3;
  }
  
  .Icon {
    font-size: 20px;
    transform: rotate(-90deg);
    font-weight: 700;

    &.Rotated {
      transform: rotate(90deg);
    }
  }

  header {
    cursor: pointer;
  }
}