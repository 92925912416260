.signedin-layout {
  position: relative;

  .signedin-layout__content {
    padding-top: $header-height;
    // min-height: calc(100vh - 18px);
    display: flex;
    align-items: flex-start;

    .main-container {
      overflow: auto;
      width: 100%;
      height: 100%;
      // min-height: calc(100vh - 18px);
      min-width: calc(100vw - 215px);
    }

    a {
      text-decoration: underline;
      color: $color-main1;

      &.dimmed {
        color: #B3B3B3;
      }
    }

    aside {
      width: $aside-width;
      height: calc(100vh - #{$header-height});
      position: fixed;
      left: 0;
      background-color: #182228;
      color: #b2b2b2;
      font-size: 16px;
      font-weight: 500;
      z-index: 999;
      font-family: 'Quicksand', sans-serif;
      transition: all 0.2s ease-in;
      overflow: auto;
      overflow-x: hidden;

      ul {
        margin-bottom: 32px;
      }

      a {
        border-left: 4px solid transparent;
        text-decoration: none;
        color: inherit;
      }

      li {
        &.main-menu {
          height: $header-height;
          display: flex;
          align-items: center;
          width: 100%;
          border-bottom: 1px solid #2e383e;

          &.last {
            a {
              border-bottom: none !important;
            }
            border-bottom: none !important;
          }

          a {
            height: $header-height;
            display: flex;
            align-items: center;
            width: 100%;

            &.active {
              // border-left-color: $color-main1;
              background-color: #f2f5f7;
              color: #182228;

              img {
                margin-top: -1px;
              }
            }
          }

          img {
            width: 26px;
            height: 26px;
            display: inline-block;
            margin: 0px 12px 0 18px;
            border-radius: 6px;
          }

          span {
            display: inline-block;
            font-size: 14px;
            opacity: 1;
            transition: all 0.2s ease-in;
          }
        }

        &.submenu {
          height: 30px;
          padding: 20px 0px;
          position: relative;
          top: -1px;
          border-top: 1px solid #182228;

          a {
            font-size: 14px;
            height: 20px;
            display: flex;
            align-items: flex-start;

            &.active {
              background-color: #182228;
              color: #fff;
            }

            span {
              font-size: 14px;
              margin-left: 55px;

              &.active {
                color: #fff;
              }
            }
          }
        }

        &.kid-menu {
          border-bottom: none;
          height: 34px;
          transition: all 0.2s ease-in;

          a {
            margin-left: 59px;
            margin-top: 7px;
            border-bottom: none;
            display: inline-flex;
          }

          .kid-menu-dot {
            background-color: $color-main1;
            width: 9px;
            height: 9px;
            position: relative;
            left: -25px;
            top: 4px;
            border-radius: 50%;
            visibility: hidden;
          }

          &.active {
            a {
              margin-left: 50px;
            }

            .kid-menu-dot {
              visibility: visible;
            }
          }

          &.first {
            margin-top: 5px;
          }

          img {
            display: none;
          }

          span {
            display: inline-block;
            font-size: 14px;
            opacity: 1;
            transition: all 0.2s ease-in;
            max-width: 80px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          }
        }
      }

      &.collapsed {
        li.kid-menu {
          height: 1px;

          &.active a {
            &:before {
              visibility: hidden !important;
            }
          }
        }

        .collapse {
          width: 64px;
        }
      }

      .collapse {
        margin: 0 auto;
        width: 160px;
        height: 30px;
        position: fixed;
        bottom: 0px;
        left: 4px;
        background-color: #192229;
        transition: all 0.2s ease-in;

        &:after {
          content: '';
          border: 2px solid #A0A4A7;
          border-left: 0;
          border-top: 0;
          width: 6px;
          height: 6px;
          display: block;
          position: fixed;
          left: 35px;
          bottom: 10px;
          margin-left: -8px;
          transform: rotate(135deg);
          cursor: pointer;
          z-index: 99999;
        }
      }

      @media (max-width: 1024px) {
        display: none;
      }
    }

    .loading {
      flex: 1;
      height: 100%;
      transition: all 0.2s ease-in;
    }

    aside + .loading {
      margin-left: $aside-width;
      // min-height: calc(100vh - #{$header-height});
    }
  }

  &.collapsed {
    aside {
      width: $aside-small-width;
      li {
        &.kid-menu a span {
          font-size: 0px !important;
          opacity: 0 !important;
        }

        span {
          font-size: 0px !important;
          opacity: 0 !important;
        }
      }

      .collapse {
        &:after {
          transform: rotate(-45deg);
        }
      }
    }

    aside + * {
      margin-left: $aside-small-width;
    }

    aside + .loading {
      margin-left: $aside-small-width;

      @media (max-width: 1024px) {
        margin: 0;
      }
    }
  }

  .content-header {
    height: 72px;
    border-bottom: 1px solid $color-header-border;
    font-family: 'Quicksand', sans-serif;
    -webkit-font-smoothing: antialiased;
  }

  &.printable {
    aside {
      display: none;

      & + * {
        margin-left: 0px !important;
      }
    }

    .Header {
      position: static;
      background-color: transparent;

      .UserDropdown {
        display: none;
      }
    }

    .signedin-layout__content {
      padding-top: 0px;
    }

    .content-header {
      .badges, .chevron {
        display: none;
      }

      .date-selected {
        margin-left: 45px;
      }

      .caretakers-filter {
        position: absolute;
        right: 10px;

        &.edge {
          .badge {
              border-width: 3px !important;

              span {
                position: relative;
                top: -2px !important;
              }
          }
        }
      }

      & + .print.week {
        position: absolute;
        top: 25px;
        left: 10px;
      }
    }

    .calendar-headers {
      .calendar-kid-header {
        &:first-child.week {
          margin-left: 0px !important;
        }
      }
    }

    .add-week-event {
      display: none;
    }
  }
}

.empty-view-message {
  width: 100%;
  margin: 40px;
  text-align: center;
  font-size: $font-size-base;
}
