.DeleteSuccess {
  display: flex;
  align-items: center;
  justify-content: center;
  height: calc(100vh - 80px);
  flex-direction: column;
  width: 100vw;

  .container {
    width: 100vw;
    max-width: 497px;
    height: 490px;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.25);
    background-color: $color-white;
  }

  .LogoContainer {
    display: flex;
    justify-content: center;

    .Logo {
      height: 70px;
      margin-top: 45px;
    }
  }

  .title {
    font-family: 'Quicksand', sans-serif;
    font-size: 24px;
    font-weight: 500;
    text-align: center;
    color: #182228;
    margin-top: 56px;
    margin-bottom: 24px;
  }

  .info-text {
    font-family: 'Nunito', sans-serif;
    font-size: 14px;
    font-weight: 600;
    text-align: left;
    color: $color-text-base;
    margin-left: 70px;
  }
}
