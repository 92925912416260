.ColorPicker {
    width: 100%;

    .hue-picker {
        margin-top: 10px;
    }

    .hue-horizontal {
        top: 10px;
    }
}

.color-picker-preview {
    width: 32px;
    height: 20px;
    display: block;
    border: 1px solid #fff;
    outline: 1px solid $color-grey2;
}