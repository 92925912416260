.slide-down-appear {
    opacity: 0;
    transform: translateY(-100%);

    &.slide-down-appear-active {
        opacity: 1;
        transform: translateY(0%);
        transition: all 200ms ease-in;
    }
}

.slide-down-enter {
    opacity: 0;
    transform: translateY(-100%);

    &.slide-down-enter-active {
        opacity: 1;
        transform: translateY(0%);
        transition: all 200ms ease-in;
    }
}

.slide-up-appear {
    opacity: 1;
    transform: translateY(0%);

    &.slide-up-appear-active {
        opacity: 0;
        transform: translateY(-100%);
        transition: all 200ms ease-in;
    }
}
