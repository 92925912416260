.block { display: block; }
.hide { display: none; }
.inline-block { display: inline-block; }
.flex { display: flex; }
.flex-wrap { flex-wrap: wrap; }

.space-around { justify-content: space-around; }
.space-between { justify-content: space-between !important; }
.flex-end { justify-content: flex-end; }
.flex-row { flex-direction: row; }
.flex-column { flex-direction: column; }

.horizontal-center-content {
  display: flex;
  justify-content: center;
}

.vertical-center-content {
  display: flex;
  align-items: center;
}

.wrap { white-space: normal !important; }
.nowrap { white-space: nowrap !important; }

.fullwidth { width: 100% !important; }
.half { width: 50% !important; }
.w-30p { width: 30% !important; }
.w-25p { width: 25% !important; }
.w-10p { width: 10% !important; }
.m-w-100 { max-width: 100px; }
.m-w-140 { max-width: 140px; }

.bg-lightblue { background-color: $color-lightblue !important; }
.bg-main1 { background-color: $color-main1 !important; }
.bg-orange { background-color: $color-orange !important; }
.bg-olive { background-color: $color-olive !important; }
.bg-green { background-color: $color-success !important; }
.bg-red { background-color: $color-error !important; }
.bg-lightgrey { background-color: $color-lightgrey2 !important; }
.bg-darkgrey { background-color: $color-darkgrey !important; }
.bg-grey { background-color: $color-grey !important; }
.bg-grey2 { background-color: $color-grey2 !important; }
.bg-grey3 { background-color: $color-grey3 !important; }

.color-main { color: $color-main1 !important; }
.color-grey { color: $color-grey3 !important; }
.orange { color: $color-orange !important; }
.white { color: #fff; }
.black { color: #000 !important; }
.darkgrey { color: $color-darkgrey !important; }
.grey3 { color: $color-grey3 !important; }
.grey4 { color: $color-grey4 !important; }
.lightgrey { color: $color-grey2 !important; }
.yellow { color: $color-yellow !important; }

.success { color: $color-success !important; }
.bg-success { background-color: $color-success !important; }
.error { color: $color-error !important; }
.bg-error { background-color: $color-error !important; }
.warning { color: $color-yellow !important; }
.bg-warning { background-color: $color-yellow !important; }

.right { text-align: right !important; }
.left { text-align: left; }
.center { text-align: center !important; }

.pull-right { float: right; }
.pull-left { float: left; }
.clear { clear: both; }

.overflow-hidden { overflow: hidden; }

.pointer { cursor: pointer; }

.relative { position: relative; }
.absolute { position: absolute; }

.p-h-10 { padding-left: 10px; padding-right: 10px; }
.p-h-50 { padding-left: 50px; padding-right: 50px; }
.p-v-10 { padding-top: 10px; padding-bottom: 10px; }
.p-t-5 { padding-top: 5px; }
.p-t-50 { padding-top: 50px; }
.p-t-10 { padding-top: 10px; }
.p-t-20 { padding-top: 20px; }
.p-t-30 { padding-top: 30px; }
.p-b-10 { padding-bottom: 10px; }
.p-b-20 { padding-bottom: 20px; }
.p-b-30 { padding-bottom: 30px; }
.p-r-10 { padding-right: 10px !important; }
.p-r-15 { padding-right: 15px !important; }
.p-l-5 { padding-left: 5px; }
.p-l-10 { padding-left: 10px !important; }
.p-r-20 { padding-right: 20px; }
.p-l-20 { padding-left: 20px; }

.m-b-10 { margin-bottom: 10px !important; }
.m-b-15 { margin-bottom: 15px; }
.m-b-20 { margin-bottom: 20px !important; }
.m-b-30 { margin-bottom: 30px; }
.m-b-50 { margin-bottom: 50px; }

.m-t-0 { margin-top: 0px !important; }
.m-t-5 { margin-top: 5px; }
.m-t-10 { margin-top: 10px; }
.m-t-15 { margin-top: 15px; }
.m-t-20 { margin-top: 20px !important; }
.m-t-30 { margin-top: 30px; }
.m-t-50 { margin-top: 50px; }
.m-t-80 { margin-top: 80px !important; }
.m-t-140 { margin-top: 140px !important; }

.m-r-5 { margin-right: 5px }
.m-r-10 { margin-right: 10px }
.m-r-15 { margin-right: 15px }
.m-r-20 { margin-right: 20px }
.m-r-30 { margin-right: 30px }
.m-r-40 { margin-right: 40px }

.m-l-5 { margin-left: 5px }
.m-l-10 { margin-left: 10px }
.m-l-15 { margin-left: 15px }
.m-l-20 { margin-left: 20px }
.m-l-30 { margin-left: 30px }
.m-l-40 { margin-left: 40px }

.m-10 { margin: 10px }

.f9 { font-size: 9px !important; }
.f10 { font-size: 10px !important; }
.f11 { font-size: 11px !important; }
.f12 { font-size: 12px !important; }
.f13 { font-size: 13px; }
.f14 { font-size: 14px !important; }
.f15 { font-size: 15px; }
.f16 { font-size: 16px; }
.f18 { font-size: 18px; }
.f20 { font-size: 20px; }
.f22 { font-size: 22px; }
.f24 { font-size: 24px; }

.lh22 { line-height: 22px; }

.w300 { font-weight: 300; }
.w400 { font-weight: 400 !important; }
.w500 { font-weight: 500 !important; }
.w600 { font-weight: 600; }

.capitalize { text-transform: capitalize !important; }
.lowercase { text-transform: lowercase !important; }

.half-transparent { opacity: 0.5; }

.text-overflow { overflow: hidden; text-overflow: ellipsis; }
