.react-selectize.root-node {
    width: 140px !important;
}

.simple-select.react-selectize.default.root-node:not(.open) .react-selectize-control {
    background: none !important;
    background-image: none !important;
}

.react-selectize.default.root-node .react-selectize-control {
    border-radius: 0px !important;
    border: none !important;
    border-color: none !important;
    border-bottom: 1px solid #b3b3b3 !important;
}

.react-selectize.root-node .react-selectize-control .react-selectize-placeholder {
    line-height: 28px !important;
    font-size: 14px !important;
    font-weight: 400 !important;
    max-width: calc(100% - 45px) !important;
}

.react-selectize.default.dropdown-menu {
    .option-wrapper {
        &.highlight {
            background: $color-lightgrey !important;
        }
        .simple-option {
            font-size: 14px !important;
            font-weight: 400 !important;
            height: 32px !important;
            padding: 8px !important;
        }
    }
}

.simple-select.react-selectize.root-node .simple-value span {
    font-size: 14px !important;
    font-weight: 400 !important;
}
