.chevron {
    position: relative;

    &:after {
        width: 8px;
        height: 8px;
        content: '';
        border: 3px solid #000;
        position: absolute;
        top: calc(50% - 5px);
        display: block;
        cursor: pointer;
    }
    &:hover {
        &:after {
            transition: all 0.2s ease-in-out;
            border-color: $color-main1 !important;
        }
    }
    
    &.chevron-left {
        &:after {
            border-right: 0;
            border-top: 0;
            transform: rotate(45deg);
        }
    }

    &.chevron-right {
        &:after {
            border-left: 0;
            border-top: 0;
            transform: rotate(-45deg);
        }
    }

    &.chevron-down {
        &:after {
            border-left: 0;
            border-top: 0;
            transform: rotate(45deg);
        }
    }

    &.chevron-up {
        &:after {
            border-left: 0;
            border-top: 0;
            transform: rotate(225deg);
            top: 50%;
        }
    }

}
