@import "../../styles/includes/variables";

.access-denied-container {
  background-color: $color-background;
  padding: 20px;
  width: 100%;
  flex: 1;

  .text {
    width: 100%;
    text-align: center;
    margin: 20px 0;
  }

  .title {
    font-size: 1.5rem;
  }

  .description {
    font-size: 1.2rem;
  }

  .redirect-home-container {
    width: 70%;
    max-width: 300px;
  }

  .redirect-home {
    display: block;
    margin: 0 auto;
  }
}
