body {
  font-size: 18px;
  font-weight: 500;
  line-height: 1.33;
  color: $color-text-base;
  background-color: $color-background;
  font-family: 'Nunito-Light', sans-serif;
  -webkit-font-smoothing: antialiased;
}

h1, h2 {
}

.__floater.__floater__open {
  h2 {
    font-family: 'Nunito', sans-serif;
    text-align: left;
    padding-left: 5px;
  }
}

span.monos {
  .mono {
    width: 13px !important;
    display: inline-block;
    text-align: center;

    &.letter4, &.letter7, &.letter10 {
      margin-right: 5px;
    }
  }
}

@media print {
  * {
      -webkit-print-color-adjust: exact;
  }
}
