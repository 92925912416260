.Accounting, .Budget {
    padding-bottom: 60px;

    .dropzone-container {
        width: 90%;
    }

    .active-date {
        width: 250px;
        border-right: 1px solid $color-header-border;
        height: 72px;
        padding: 20px 30px;
        font-size: 25px;
        letter-spacing: -0.5px;
        color: $color-main1;
        cursor: pointer;
        float: left;

        &:hover {
            span {
                transition: all 0.2s ease-in-out;
                color: $color-main1;
            }
        }
    }

    .date-filter {
        width: 100%;
        padding: 0px 25px;
        text-align: center;
        margin: 0 auto;

        & > * {
            display: inline-block;
        }

        .month-selection {
            margin: 18px auto;
            text-align: center;

            .year {
                color: $color-grey2;
                font-size: 25px;
                margin-left: 30px;
                margin-right: 30px;
            }

            .badge {
                margin: 5px 10px;
                position: relative;
                top: -5px;
            }
        }

        .chevron {
            height: 72px;
            display: inline-block;
            position: absolute;
            margin-top: -1px;

            &.chevron-left {
                margin-left: -20px;
            }

            &.chevron-right {
                margin-left: 20px;
            }
        }
    }

    .summaries {
        width: 90%;
        padding-bottom: 50px;
        margin: 0 auto;

        .summary {
            display: inline-block;
            width: 50%;
            margin: 25px auto;
            text-align: center;

            &:last-child {
                padding-left: 60px;
            }

            & > label {
                display: block;
                width: 100%;
                color: #516068;
                text-transform: uppercase;
                text-align: left;
            }

            .summary-box {
                display: inline-block;
                padding: 10px 0;
                width: 33%;

                label {
                    font-size: 14px;
                    display: block;
                    color: $color-grey2;
                    text-align: left;
                }

                .value {
                    display: block;
                    font-size: 25px;
                    white-space: nowrap;
                    text-align: left;

                    .currency {
                        color: $color-grey2;
                        font-size: 14px;
                        font-weight: 600;
                        margin-left: 5px;
                    }
                }

                .dot {
                    width: 7px;
                    height: 7px;
                    border-radius: 50%;
                    display: inline-block;
                    margin-left: 5px;
                    vertical-align: middle;
                    position: relative;
                    top: -1px;
                }
            }

            &.budget {
                .summary-box {
                    width: 33%;
                }
            }

            &:last-child {
                border-left: 1px solid $color-header-border;
            }
        }
    }

    .finance-history-container {
      display: flex;
      flex-direction: column;
      align-items: center;
    }

    .add-new-finances {
        margin: 0 auto;
        width: 90%;
        position: relative;
        top: 78px;
    }

    .finances-pending, .finances-history {
        margin: 0 auto;
        overflow: hidden;
        position: relative;
        width: 90%;
        padding-left: 9px;
        padding-right: 9px;
        background-color: #fff;

        &[rel='opened'] {
            .table {
                opacity: 1;
                transform: translateY(0%);
            }
        }

        &[rel='closed'] {
            .table {
                opacity: 0;
                transform: translateY(-100%);
                margin-top: -999px;
            }
        }

        .table {
            margin: 0px auto;
            padding-top: 25px;
            transition: all 0.3s ease-in;
        }

        .notification-container {
            padding: 20px;
            text-align: center;
        }
    }

    .section-title {
        color: #516068;
        font-family: 'Quicksand', sans-serif;
        font-weight: 600;
        font-size: 14px;
        letter-spacing: 1.4px;
        text-transform: uppercase;
        margin: 30px auto 8px;

        & > div {
            display: inline-block;
        }

        .chevron-up, .chevron-down {
            &:after {
                top: -6px;
                left: 10px;
                width: 6px;
                height: 6px;
                border-color: #516068;
                border-width: 2px;
            }
        }
        .chevron-down {
            &:after {
                top: -9px;
            }
        }
    }

    .history-filters {
        margin-bottom: 17px;
        display: flex;
        justify-content: center
    }
}

.Budget {
    .section-title {
        margin-top: 200px !important;
    }
}


/* MODAL */

.finance-modal {
    .badges-section {
        margin-bottom: 5px;

        .react-datepicker__input-container {
            width: 100% !important;
        }
    }

    .title {
        margin-bottom: 5px;
        padding-bottom: 5px !important;
    }

    .total-amount {
        margin-top: 30px;
    }

    .split-section {
        height: 40px;

        .half {
            margin-top: 15px;

            input[type='radio'] {
                width: 10%;
                float: left;
                background-color: transparent;
            }

            input[type='text'], span {
                width: 80%;
                float: right;
                font-size: $font-size-base;
            }

            label {
                transform: none !important;
            }

            input[type='text']:focus ~ label,
            input[type="text"]:not([value=""]) ~ label {
                left: 40px !important;
                display: none;
            }
        }

        .relative {
          justify-content: center;
          display: flex;
        }
    }

    .attachment-section {
        font-size: $font-size-base;

        .zmdi {
            font-size: 20px;

            &:hover {
                color: $color-main1;
            }
        }

        .name {
            margin-left: 10px;
            position: relative;
            top: 3px;
            width: 75%;
            display: inline-block;
            text-overflow: ellipsis;
            overflow: hidden;
            font-size: 14px;
            font-weight: 600;
            white-space: nowrap;
            margin-right: 12px;
        }

        .attachment-dropzone {
            cursor: pointer;

            .FileUploaderCustomButton {
                position: initial;
                display: inline-block;
            }
        }
    }

    .error {
        margin: 10px auto;
        font-size: $font-size-base;
    }
}

.split-requests-modal {
    .form__summary {
        margin-top: 0px !important;
    }

    .split-requests-modal-note {
        font-size: 14px;
        margin: 10px 0 20px;
    }
}

.split-request-file {
    a {
        color: #000 !important;

        &:hover {
            color: $color-main1 !important;
        }
    }
}

.split-requests-table {
    .split-notes {
        text-overflow: ellipsis;
        max-width: 120px;
        overflow: hidden;

        span {
            display: inline-block;
        }
    }
}


/* SPLIT REQUEST */
.SplitRequestForm {
    margin: 30px auto;
    padding-top: 30px;
    width: 500px;
    background-color: #fff;
    text-align: center;

    .modal-close {
        position: absolute;
        top: -20px;
    }
}


@media screen and (max-width: 1440px) {
    .date-filter .month-selection .badge {
        margin: 5px !important;
    }

    .summary-box {
        label {
            font-size: 14px !important;
        }
        .value {
            font-size: 22px !important;
        }
    }

    .summaries {
        .summary {
            &:last-child {
                padding-left: 20px !important;
            }
        }
    }
}

.content-summaries {
    margin: 0px auto 64px;
    width: 100%;

    &.content-header {
        height: auto;
        border-bottom: none;
    }

    &.summaries-fixed {
        position: fixed;
        padding-bottom: 36px;
        background-color: $color-background;
        z-index: 9;
        width: 94%;
    }

    .summaries {
        margin-bottom: -35px;
        padding-bottom: 0px;
        box-shadow: 0 4px 2px -2px $color-grey;

        .summary {
            .summary-box {
                width: 33%;
            }
        }
    }
}

