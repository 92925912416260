.table {
    display: table;
    width: 100%;
    margin: 60px auto;
    background-color: #fff;
    border-spacing: 0px;
    border-collapse: collapse;

    .thead {
        display: table-header-group;

        .trow {
            display: table-row;

            .tcell {
                display: table-cell;
                text-transform: uppercase;
                font-size: 12px;
                color: #9f9f9f;
                letter-spacing: 0.6px;
                padding: 10px;
                text-align: left;
                font-weight: 600;
                box-sizing: border-box;
            }
        }
    }

    .tcell {
        background: #fff;
        &:first-child {
            padding-left: 24px !important;
        }

        &:last-child {
            padding-right: 24px !important;
        }
    }

    .trow:not(.week-view) {
        &:last-child {
            .tcell {
                background: none;
            }
        }
    }

    .tbody {
        display: table-row-group;
        .trow {
            display: table-row;
            .tcell {
                display: table-cell;
                padding: 10px;
                font-size: 14px;
                vertical-align: top;
                white-space: nowrap;
                border-top: 1px solid $color-lightgrey;
                border-collapse: collapse;
                box-sizing: border-box;

                &.person {
                    border-top: none;
                }

                &.small {
                    width: 40px;
                }

                &.medium {
                    width: 140px;
                }

                &.icons {
                    text-align: right;
                    margin-right: 20px;

                    .zmdi {
                        font-size: 20px;
                        cursor: pointer;
                        display: inline;

                        &:hover {
                            color: $color-main1;
                        }
                    }
                }

                a {
                    text-decoration: none;
                    font-size: 14px;
                    color: $color-main1;

                    &:hover {
                        color: #000;
                    }
                }
            }

            &:not(.week-view) {
                &.active, &:hover {
                    .tcell {
                        border-top: 1px solid $color-text-heading;
                        border-bottom: 1px solid $color-text-heading;
                        background-color: #f9f9f9 !important;

                        &:first-child {
                            border-left: 1px solid $color-text-heading;
                            padding-left: 23px !important;
                        }
                        &:last-child {
                            border-right: 1px solid $color-text-heading;
                            padding-right: 24px !important;
                        }

                        &.icons {
                            .zmdi {
                                visibility: visible !important;
                            }
                        }
                    }
                }
            }
        }
    }
}

@media screen and (max-width: 1280px) {
    .tcell {
        font-size: 13px;
    }

    .hidden-small-res {
        display: none !important;
    }
}
