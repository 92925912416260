$flags-list: (
  GB,
  NO,
  SE,
  DK,
);


.language-select {
  display: flex;
  justify-content: space-between;
  align-items: center;

  label {
    text-transform: none !important;
    font-size: 15px !important;
    font-weight: 400 !important;
    line-height: 22px;
  }

  .flag {
    cursor: pointer;
    opacity: 0.55;
    border: 1px solid #fff;
    width: 30px;
    height: 21px;
    background-size: cover;

    &:hover,
    &.active {
      opacity: 1;
      outline: 1px solid $color-main1;
    }

    span {
      position: relative;
      top: -3px;
    }

    @each $flag in $flags-list {
      &.#{nth($flag, 1)} {
        background-image: url('../../static/assets/flags/#{$flag}.png');
      }
    }
  }
}
