.InfoModalContent {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  flex-direction: column;
  width: 100%;
  
  .logo.tada .logo-img {
    height: 130px;
    width: 130px;
  }

  .Title {
    font-family: 'Quicksand', sans-serif;
    font-size: 24px;
    font-weight: 500;
    margin-top: 56px;
    margin-bottom: 24px;
    text-align: center;
  }

  .InfoText {
    font-family: 'Nunito', sans-serif;
    font-size: 14px;
    font-weight: 700;
    text-align: center;
  }
}
