.Reports {
    .form {
        margin: 30px auto;
        width: 735px;
        background-color: #fff;
        padding: 40px 60px;


        label {
            font-size: $font-size-base;
            margin: 5px 10px 5px 0;
        }

        .form__row {
            margin-top: 0px;
            padding: 30px 0px 10px;

            &:first-child {
                margin-top: 0px;
            }
        }

        .badge {
            margin-bottom: 10px;
        }
    }

    .periods {
        display: flex;
        justify-content: space-between;
        margin-bottom: 10px;

        .date-time-section {
            .react-datepicker__input-container {
                width: 260px !important;
                padding: 4px 0;
            }

            .react-selectize.root-node {
                width: 260px !important;
            }

            .react-selectize-reset-button-container {
                display: none !important;
            }
        }

        .separator {
            display: inline-block;
            margin: 10px 30px 0 0;
        }
    }
}

.ReportPrint {
    height: 100%;

    .title {
        font-family: 'Quicksand', sans-serif;
        font-weight: 700;
        font-size: 14px;
        text-transform: uppercase;
        text-align: center;
        margin: 40px auto 45px;
    }

    .report-container {
        width: 660px;
        margin: 0 auto 35px;
    }

    .report-header {
        .logo {
            margin: 45px auto 20px;
            text-align: center;

            img {
                width: 48px;
                height: 44px;
            }
        }

        .title, .week {
            font-family: 'Quicksand', sans-serif;
            font-weight: 500;
        }

        .title {
            font-size: 26px;
            text-align: center;
            margin: 20px auto 6px;
        }

        .week {
            font-size: 15px;
            letter-spacing: 1.5px;
            text-transform: uppercase;
            margin: 0px auto 72px;
            text-align: center;
        }
    }

    .report-general-summary {
        border-top: 1px solid #E1E5E7;
        border-bottom: 1px solid #E1E5E7;
        height: 110px;
        padding: 30px 10px;
        margin: 0 auto;
        display: flex;
        justify-content: space-around;

        .summary {
            display: inline-block;
            width: 20%;
            text-align: center;

            & > label {
                display: block;
                width: 100%;
                color: #516068;
                text-transform: uppercase;
                text-align: left;
            }

            .summary-box {
                display: inline-block;
                width: 100%;

                label {
                    font-family: 'Nunito', sans-serif;
                    font-weight: 300;
                    font-size: 16px !important;
                    display: block;
                    color: #000;
                    text-align: left;
                }

                .value {
                    font-family: 'Quicksand', sans-serif;
                    font-weight: 500;
                    font-size: 24px;
                    display: block;
                    font-size: 25px;
                    white-space: nowrap;
                    text-align: left;

                    .currency {
                        color: $color-grey2;
                        font-size: 14px;
                        font-weight: 600;
                        margin-left: 5px;
                    }
                }
            }

          &:nth-of-type(2) {
            label {
              text-transform: capitalize;
            }
          }
        }
    }

    .report-summary {
        border-bottom: 1px dashed #E1E5E7;

        .user-name {
            font-family: 'Quicksand', sans-serif;
            font-weight: 500;
            font-size: 26px;
            color: #182228;
        }

        .amounts {
            margin-top: 23px;
            margin-bottom: 40px;

            div {
                width: 200px;
                display: inline-block;

                .amount-type {
                    display: block;
                    font-family: 'Nunito', sans-serif;
                    font-weight: 300;
                    font-size: 16px;
                    color: #000;
                }

                .value {
                    font-family: 'Quicksand', sans-serif;
                    font-weight: 500;
                    font-size: 24px;

                    .currency {
                        font-family: 'Quicksand', sans-serif;
                        font-weight: 900;
                        font-size: 14px;
                        color: #4A4A4A !important;
                        margin-left: 4px;
                    }
                }
            }
        }

        .categories {
            margin-top: 30px;
            margin-bottom: 40px;

            &:last-child {
                margin-top: 48px;
            }

            p {
                margin-bottom: 27px;
                font-family: 'Nunito', sans-serif;
                font-weight: 400;
                font-size: 16px;
            }

            .category {
                margin-bottom: 24px;

                .category-name {
                    font-family: 'Nunito', sans-serif;
                    font-weight: 300;
                    display: inline-block;
                    width: 140px;
                    font-size: 15px;
                }

                .chart {
                    position: relative;
                    top: -11px;
                    display: inline-block;
                    margin-right: 32px;

                    .path {
                        position: absolute;
                        left: 0px;
                        top: 5px;
                        background-color: #c0c0c0;
                        height: 1px;
                        width: 100%;
                    }

                    .bar {
                        position: absolute;
                        left: 0px;
                        top: 0px;
                        background-color: #516068;
                        height: 11px;
                        border-radius: 10px;
                    }
                }

                .expense {
                    display: inline-block;
                    width: 120px;
                    text-align: right;
                    white-space: nowrap;

                    .amount {
                        font-family: 'Quicksand', monospace;
                        font-weight: 500;
                        font-size: 22px;
                        color: #182228;
                        text-align: right;
                        letter-spacing: 0px;
                        display: inline-block;
                        position: relative;
                        top: 2px;
                    }

                    .percentage {
                        font-family: 'Quicksand', sans-serif;
                        font-weight: 400;
                        color: #4a4a4a;
                        width: 50px;
                        text-align: right;
                        font-size: 15px;
                        display: inline-block;
                    }
                }
            }
        }
    }

    .report-transactions {
        .table {
            .thead {
                .trow {
                    .tcell {
                        color: #516068;
                        padding-left: 5px;
                        padding-right: 5px;
                    }
                    &:first-child {
                        .tcell:first-child {
                            padding-right: 0px;
                        }
                    }
                }
            }
            .tbody {
                .trow {
                    pointer-events: none;

                    .tcell {
                        padding: 15px 5px 14px;
                    }

                    &:first-child {
                        .tcell {
                            border-top-color: #516068;
                        }
                    }
                }
            }

            .tcell:first-child {
                padding-left: 0 !important;
            }
            .tcell:last-child {
                padding-right: 5px !important;
            }
        }
    }
}

@media print {
    .page {
        page-break-after: always;
        height: 29.7cm;
    }

    .report-container {
        margin: 0 auto 0 !important;
    }

    .report-summary {
        border-bottom: 0px !important;
    }
}
