@import "../../styles/includes/variables";

.unsupported-mobile-container {
  background-color: $color-background;
  align-items: center;
  padding: 60px 20px 20px;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 9999;

  .text {
    width: 100%;
    text-align: center;
    margin: 20px 0;
  }

  .title {
    font-size: 1.5rem;
  }

  .description {
    font-size: 1.2rem;
  }

  .link-button-container {
    width: 70%;
    max-width: 300px;
  }

  .link-button {
    display: flex;
    justify-content: center;
  }
}

@media (min-width: 1025px) {
  .unsupported-mobile-container.vertical-center-content {
    display: none;
  }
}
