.ReactModal__Overlay--after-open {
    z-index: 99999;
    background-color: rgba(0, 0, 0, 0.5);

    .ReactModal__Content--after-open {
        top: 80px !important;
        bottom: 80px;
        max-height: 800px;
        height: auto;
        width: 520px;
        border-radius: 0px !important;
        margin: 0 auto;
        padding: 20px 60px !important;
        box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.5);

        &[aria-label='Budget Modal'],
        &[aria-label='Finance Modal'],
        &[aria-label='Calendar Modal'] {
            max-height: 720px;
            height: auto;
        }

        &[aria-label='Calendar Disabled Modal'] {
          max-height: 600px !important;
          height: auto;
        }

        &[aria-label='Calendar Print Modal'] {
            max-height: 420px !important;

            &.extended {
                max-height: 540px !important;
            }
        }

        &[aria-label='Notifications Modal'] {
          max-height: 600px !important;
          height: auto;
        }

        &[aria-label='Split-Requests  Modal'] {
            width: 450px !important;
            max-height: 250px !important;
        }

        &[aria-label='Split-Requests With-Note Modal'] {
            width: 450px !important;
            max-height: 310px !important;
        }

        &[aria-label='Finance-Split Modal'] {
            max-height: 310px !important;
        }

        &[aria-label='Documents Modal'] {
            max-height: 420px !important;
        }

        &[aria-label='Documents Share Modal'] {
            max-height: 280px !important;
        }

        &[aria-label='KidsProfile-leaf Modal'],
        &[aria-label='KidsProfile-document Modal'] {
            padding: 40px 60px !important;
            max-height: 380px !important;
        }

        &[aria-label='KidsProfile-card Modal'] {
            padding: 40px 60px !important;
            max-height: 274px !important;
        }

        &[aria-label='KidsProfile-personal Modal'] {
            padding: 40px 60px !important;
            max-height: 580px !important;
        }

        &[aria-label='Finance Confirm Modal'],
        &[aria-label='Documents Confirm Modal'],
        &[aria-label='Calendar Confirm Modal'],
        &[aria-label='Default Confirm Modal'],
        &[aria-label='Default Modal'] {
            padding: 50px 60px !important;
            max-height: 230px !important;
            height: auto;
        }

        &[aria-label='Default Confirm Extended Modal'] {
            max-height: 300px !important;
        }

        &[aria-label='Default Confirm Modal'],
        &[aria-label='Default Confirm Extended Modal'] {
            width: 480px;

            .form__summary {
                margin-top: 0px !important;
            }
        }

        &[aria-label='Invitation Modal'] {
            max-height: 320px !important;

            @for $i from 0 through 20 {
                &.homes#{$i} {
                    max-height: 450 + $i * 60px !important;
                }
            }
        }

        &[aria-label='2homesKidsInvitation Modal'] {
          max-height: 720px !important;
        }

        &[aria-label='DeleteAccount Modal'] {
          max-height: 700px !important;
          height: auto;
        }

        &[aria-label='DeleteAccount--small Modal'] {
          max-height: 500px !important;
          height: auto;
        }

        &[aria-label='Info Modal'] {
            max-height: 620px !important;
        }

        .badges-section {
            .title {
                padding-top: 18px;
                padding-bottom: 8px;
                font-size: 14px;

                &.inline {
                    display: inline-block;
                    margin-right: 10px;
                    position: relative;
                    top: 1px;
                }
            }
        }

        .cancel {
            &.error {
                width: 30%;
                position: absolute;
                right: 50px;
                top: 19px;
                opacity: 0.8;

                &:hover {
                    opacity: 1;
                }
            }
        }

        p {
            &.modal-question {
                padding: 0px 70px;
            }

            .cancel {
                &:not(.error) {
                    display: block;
                    margin-bottom: 20px;
                }
            }
        }

        .bordered-section {
            border-top: 1px solid #efeefe;
        }
    }
}

.modal-close {
    position: absolute;
    right: 15px;
    top: 15px;
    opacity: 0.5;
}
