.Header {
  height: $header-height;
  width: 100%;
  min-width: 100%;
  display: flex;
  position: fixed;
  align-items: flex-start;
  justify-content: space-between;
  background-color: #fff;
  z-index: 99;
  border-bottom: 1px solid #f2f5f7;

  .logo {
    max-width: $aside-width;
    height: 100%;
    margin-bottom: 0;
    transition: all 0.2s ease-in;

    a {
      width: 100%;
      text-align: center;
    }

    img {
      height: 30px;
      margin: 20px 0 0 -18px;
      display: inline-block;
    }

    &.collapsed {
      max-width: $aside-small-width;

      img {
        margin: 20px auto;
      }
    }
  }

  > * {
    height: 100%;
    flex: 1;
  }

  .SearchForm {
    border-left: 1px solid #f2f2f2;
    position: relative;

    form {
      height: 100%;

      img {
        position: absolute;
        left: 27px;
        top: 29px;
        z-index: 10;
        pointer-events: none;
      }

      input {
        display: block;
        height: 100%;
        width: 100%;
        padding-left: 50px;
        padding-right: 10px;
        z-index: 1;
      }
    }

    @media (max-width: 1024px) {
      border-color: transparent;
    }
  }

  .MessagesNotificator {
    max-width: 119px;
    position: relative;

    i {
      position: absolute;
      right: 12px;
      top: 55%;
      margin: -15px 0 0 -15px;
      z-index: 1;
      font-size: 24px;
      opacity: 0.9;
    }

    .envelope {
      position: absolute;
      left: 50%;
      top: 50%;
      margin: -12px 0 0 -7px;
      z-index: 1;
      width: 26px;
      height: 22px;
      background-color: #d4d4d4;
    }

    span {
      display: block;
      background-color: transparent;
      color: $color-main1;
      font-size: 12px;
      width: 22px;
      height: 16px;
      position: absolute;
      right: -14px;
      top: 25px;
      margin-left: 27px;
      z-index: 1;
      text-align: left;

      &:before {
        content: '';
        display: block;
        background-color: $color-main1;
        position: absolute;
        width: 7px;
        height: 7px;
        left: -13px;
        top: 7px;
        margin-top: -5px;
        border: 2px solid #fff;
        border-radius: 5px;
        z-index: 10;
      }
    }
  }

  .UserLogout {
    max-width: 291px;
    padding-top: 26px;
    position: relative;
    cursor: pointer;
    text-align: right;
    font-size: 16px;
    padding-right: 28px;

    a:hover {
      color: $color-main1;
    }
  }

  .UserDropdown {
    max-width: 291px;
    padding-top: 18px;
    border-left: 1px solid #f2f2f2;
    position: relative;
    font-size: 14px;
    cursor: pointer;

    &:after {
      content: '';
      border: 1px solid $color-main1;
      border-left: 0;
      border-top: 0;
      width: 6px;
      height: 6px;
      display: block;
      position: absolute;
      right: 42px;
      top: 50%;
      margin-top: -7px;
      transform: rotate(45deg);
    }

    &.no-dropdown {
      &:after {
        display: none;
      }
    }

    .avatar {
      display: inline-block;
      width: 36px;
      height: 36px;
      margin-top: 1px;
      margin-left: 22px;
      margin-bottom: 1px !important;
      border-radius: 50%;
      background-color: #d4d4d4;
      border: 1px solid #F5754E;

      img {
        width: 34px;
        height: 34px;
        border-radius: 50%;
      }
    }

    p {
      display: inline-block;
      margin-left: 11px;
      vertical-align: top;

      span {
        display: block;

        &:first-of-type {
          margin-top: 2px;
        }
      }
    }

    .dropdown {
      background-color: #fff;
      padding: 10px;
      box-shadow: 0px 1px 4px -2px rgba(0, 0, 0, 0.3);

      ul, li {
        width: 100%;
      }

      li {
        list-style: none;
        padding-left: 30px;
        margin-bottom: 10px;

        a {
          display: block;
        }
      }
    }

    .empty-image {
      width: 42px;
      height: 42px;
      display: inline-block;
      padding: 13px 8px;
      margin-left: 22px;
      text-align: center;
    }
  }

  .Help {
    display: flex;
    align-items: center;
    justify-content: center;
    max-width: 62px;

    &Button {
      padding: 20px;
    }

    i {
      font-size: 24px;
      opacity: 0.6;

      &.highlighted {
        box-shadow: 0 0 8px 6px $color-main1;
        border-radius: 50%;
        width: 20px;
        height: 20px;
        line-height: 20px;
      }
    }
  }

  @media (max-width: 1024px) {
    width: 100vw;
    min-width: unset;
  }
}
