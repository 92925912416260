/* Filepicker CSS */
.filepicker {
    font-family: 'Nunito', sans-serif;

    span {
        text-align: center;
        display: inline-block;
        margin-top: 26px;
        width: 100%;
        font-size: 15px;
    }
}

.widget .panel.local .upload_button_holder {
    .button {
        background: $color-main1 !important;
        font-size: 18px !important;
        line-height: 24px !important;
        padding: 9px 30px 4px 30px !important;
    }
}

#cloudinary-navbar {
    ul {
        li:active {
            border-bottom-color: $color-main1 !important;
        }
    }
}

.filepicker-button {
    color: $color-main1;
    font-size: $font-size-base;
    text-align: center;
    padding: 5px;
    border-radius: 5px;
    min-height: 60px;
    width: 100%;
    height: 72px;
}

.dropzone-container {
    margin: 20px auto 0px;
    width: 90%;
    cursor: pointer;

    .filepicker {
        border: 1px solid $color-main1;
        color: $color-main1;
        border-radius: 0px;
        background-color: #FFFCF7;
        height: 74px;
    }
}

.custom-filestack-avatar {
    color: $color-main1;
    border: none;
    text-decoration: none;
    font-family: 'Nunito', sans-serif;
    font-weight: 400;
    font-size: 14px;
    top: 30%;
    width: 100%;
}


/* Icon */
.filepicker-file-icon
{
    position: relative;

    display: inline-block;

    margin: 1.5em 0 2.5em 0;
    padding-left: 45px;

    color: black;
}
.filepicker-file-icon::before
{
    position: absolute;
    top: -7px;
    left: 0;

    width: 29px;
    height: 34px;

    content: '';

    border: solid 2px #7F7F7F;
    border-radius: 2px;
}
.filepicker-file-icon::after
{
    font-size: 11px;
    line-height: 1.3;

    position: absolute;
    top: 9px;
    left: -4px;

    padding: 0 2px;

    content: 'file';
    content: attr(data-filetype);
    text-align: right;
    letter-spacing: 1px;
    text-transform: uppercase;

    color: #fff;
    background-color: #000;
}
.filepicker-file-icon .fileCorner
{
    position: absolute;
    top: -7px;
    left: 22px;

    width: 0;
    height: 0;

    border-width: 11px 0 0 11px;
    border-style: solid;
    border-color: white transparent transparent #920035;
}
