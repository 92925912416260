@import './CalendarDatePicker';
@import './CalendarBig';

.Calendar {
    .datepicker-container {
        display: block !important;
        padding: 0px !important;
        width: 100vw;
        background-color: rgba(0, 0, 0, 0.3);
        height: 100vw;
        position: fixed;
        top: 146px;
        z-index: 999;
        margin-top: -3px;

        .react-datepicker {
            width: calc(100vw - 215px);
            border-radius: 0px !important;
            box-shadow: none !important;
            text-align: center;
            display: flex;
            justify-content: space-around;

            .react-datepicker__current-month {
                display: block !important;
            }
        }
    }

    .calendar-header {
        width: calc(100vw - 215px);
        position: fixed;

        & > div {
            display: inline-block;
            margin: 18px 28px;
        }

        .date-container {
          display: inline-flex;
          margin: 0;

          .date-selected {
            margin: 0 44px 0 18px;
          }
        }

        .chevron {
          top: -5px;
          right: 12px;
          position: relative;

          &:after {
            top: 18px;
          }

          &.chevron-up:after {
            top: 24px;
          }
        }

        .caretakers-filter {
            position: absolute;
            right: 12px;
            top: 6px;
        }

        .badge.circle {
            &.chevron-left {
                span {
                    left: -2px;
                }
            }
            &.chevron-right {
                span {
                    left: -1px;
                }
            }
        }
    }

    .content-header {
        &.week {
            padding-right: 40px;
        }

        .caretakers-filter {
            right: 46px !important;
        }
    }

    .print {
        cursor: pointer;
        position: fixed;

        &.header {
            right: 12px;
            top: 98px;
        }

        &.day {
            display: none;
        }

        i {
            font-size: 22px;
            color: $color-grey2;
        }

        &:hover {
            i {
                color: $color-main1;
            }
        }

        &.calendars {
            height: 100% !important;
        }

        .date-header-row {
            display: block !important;
        }

        .table {
            &.calendar-week {
                .date-header {
                    display: none;
                }
            }

            &.edge {
                .bubble {
                    border-bottom-width: 3px !important;
                }
            }
        }
    }
}

.add-week-event {
    position: absolute;
    margin-top: 15px;
    margin-left: 35px;
}

.calendar-headers {
    width: 100%;
    display: flex;
    flex: 1;
    flex-direction: row;
    border-left: 1px solid #e0e4e7;
    border-bottom: none;

    .calendar-kid-header {
        display: flex;
        border-right: 1px solid #e0e4e7;
        overflow: hidden;

        .kid-header {
            display: flex;
            width: 100%;
            border-bottom: 1px solid #e0e4e7;
            padding: 14px 20px;
            background-color: #fff;

            .kid-avatar {
                width: 48px;
                height: 48px;

                img {
                    width: 48px;
                    height: 48px;
                    border-radius: 50%;
                    border: 1px solid #F5754E;
                }

                .empty-image {
                    width: 48px;
                    height: 48px;
                }
            }

            .kid-names {
                margin-left: 10px;
                font-size: $font-size-base;
                overflow: hidden;

                span {
                    display: block;
                    white-space: nowrap;
                }
            }
        }

        &:first-child {
            &.day {
                margin-left: 85px;
                .kid-header {
                    border-left: 1px solid #e0e4e7;
                }
            }

            &.week {
                margin-left: 82px;
                border-bottom: 1px solid #e0e4e7;
                border-left: none;

                .kid-header {
                    border-left: 1px solid #e0e4e7;
                    border-bottom: none;
                }
            }
        }

        &.week {
            border-left: 1px solid #e0e4e7;
            border-right: none;

            &:last-child {
                border-right: 1px solid #e0e4e7;
            }
        }
    }
}

.date-selected {
    font-size: 24px;
    letter-spacing: -0.5px;
    top: 5px;
    position: relative;

    .day, .week, .month, .year {
        color: $color-grey2;

        &.selected {
            color: #000;
            cursor: pointer;

            &:hover {
                transition: all 0.2s ease-in-out;
                color: $color-main1;
            }
        }
    }
}

.arrow-container {
  width: 25px;
  height: 24px;
  position: absolute;
  left: -7px;
  top: -9px;
  background-color: $color-background;
  border-radius: 5px;
}


/* WEEK VIEW */
.date-header-row {
    display: none !important;
    width: 100%;
    text-align: center !important;
    font-weight: 700 !important;
    font-size: 15px !important;
    text-transform: capitalize !important;
    background-color: #fcfcfc !important;
    padding: 15px 0px;
}

.calendar-week {
    width: 100% !important;
    margin: 0px auto !important;

    .thead, .tbody {
        .tcell {
            height: 50px !important;
            white-space: normal !important;
            padding-bottom: 0px !important;
            box-sizing: content-box !important;
        }
    }

    .thead {
        .date-header {
            padding-top: 16px !important;
        }
    }

    .tbody {
        .tcell {
            border: none !important;
        }
    }

    .date-header {
        font-weight: 700 !important;
        font-size: 15px !important;
        width: 90px !important;

        .day-name {
            margin: 0 auto;
            text-align: center;
            text-transform: initial;
            color: #516068;
            font-family: 'Quicksand', sans-serif;
            font-weight: 500;
            font-size: 12px;
            display: block;
            width: 55px;
        }
    }

    .person {
        background-color: #fff;

        &.longterm {
            margin-top: 10px;
            margin-bottom: 10px;
            padding: 0px;

            &:first-child {
                margin-top: 0px;
            }

            .times {
                font-family: 'Nunito', sans-serif;
                font-weight: 300;
                font-size: 14px;
                text-transform: lowercase;
            }

            .caretaker {
                position: absolute;
                top: 6px !important;
                right: 10px;
                text-transform: lowercase;
                font-family: 'Nunito', sans-serif;
                font-weight: 300;
            }
        }

        &.normal {
            margin-bottom: 10px;

            .caretaker {
                position: absolute;
                top: 13px !important;
            }
        }

        &.pointer:hover, &.pointer.longterm:hover {
            outline: 1px solid $color-table-header-border !important;
        }
    }

    .tcell:not(.date-header) {
        .tcell-inside {
            border-top: 1px solid $color-lightgrey !important;
            padding-top: 10px;
            position: relative;

            .tcell-inside-add-event {
                font-size: 14px !important;
                font-weight: 400;
                padding: 5px 0px;
                display: block;
                text-align: center !important;
                background-color: #EBEEF0 !important;
                text-transform: lowercase !important;
                color: #000 !important;
                height: 26px;
                width: 100%;
                opacity: 0;
                cursor: pointer;
            }

            &:hover {
                cursor: pointer;
                .tcell-inside-add-event {
                    opacity: 1;
                }
            }
        }
    }

    .thead {
        .trow {
            .tcell {
                .tcell-inside.no-border-top {
                    border-top: none !important;
                    padding-top: 0px !important;
                }
            }
        }
    }

    .week-event {
        .caretaker {
            color: $color-grey3;

            .avatar {
                width: 24px;
                height: 24px;
                border-radius: 50%;
                float: right;
                margin: 0px 1px 10px 10px;
            }
        }

        .bubble {
            font-family: 'Nunito', sans-serif;
            font-weight: 300;
            color: #000;
            text-transform: none;
            text-align: left;

            .kid-dot {
                width: 12px;
                height: 12px;
                border-radius: 50%;
                margin-right: 5px;
                border: 2px solid #fff;
            }
        }

        .details {
            width: calc(100% - 24px);
            padding: 5px;

            .times {
                font-family: 'Nunito', sans-serif;
                font-weight: 300;
                color: $color-darkgrey;
            }

            .title {
                font-size: 14px;
                font-weight: 500;
                min-height: 20px;
                font-family: Nunito, sans-serif;
            }

            .notes {
                margin-top: 15px;
                min-height: 20px;
            }
        }

        .bubble + .details {
            padding-left: 5px;
            margin-top: 2px;
        }

        .normal {
            .caretaker {
                position: absolute;
                top: 10px;
                right: 5px;
                text-align: right;
            }

            .details {
                padding: 10px 14px;
            }
        }

        .longterm {
            .details {
                padding-left: 10px;
            }
        }
    }

    .tcell-inside {
        width: auto;
        height: 100%;
        min-height: 50px;
        padding-bottom: 10px;

        .week-note-notificator {
            width: 16px;
            height: 8px;
            position: absolute;
            bottom: 14px;
            right: 14px;
            background: url('../../../static/assets/more.png');
            background-size: cover;
            cursor: pointer;

            &.white {
                background: url('../../../static/assets/more-white.png');
                background-size: cover;
            }
        }
    }
}

.calendar-week-container {
    background-color: #fff;

    .table.calendar-week {
        .thead, .tbody {
            .tcell {
                background-image: none;
                background: #fff;
                border-right: 1px solid $color-lightgrey !important;

                &:last-child {
                    padding-right: 10px !important;
                }

                &.date-header {
                    padding: 10px 14px !important;
                    background-color: #f2f5f7;
                    border-right: 1px solid $color-lightgrey !important;
                }
            }
        }

        .thead {
            .tcell {
                vertical-align: top;
            }
        }

        &.edge {
            border-collapse: initial !important;
        }
    }
}


/* MODAL */
.calendar-modal {
    &.disabled {
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: space-around;
    }

    .event-name {
        margin-top: 27px;
    }

    .date-time-section {
        margin-top: 12px;
        margin-bottom: 22px;
        width: 170px;

        label {
            display: block;
            font-size: 12px;
            color: $color-grey;

            &.repeatable-ends {
                width: 110px;
                text-align: left;
                float: right;
            }
        }

        input {
            font-size: 14px !important;
        }

        .rc-time-picker {
            & ~ div {
                display: inline-block !important;
            }
        }

        .rc-time-picker-input {
            width: 45px;
            margin-right: 5px;
            border: none;
            border-bottom: 1px solid $color-text-input-placeholder;
            border-radius: 0;
            color: #000;
            padding: 4px 0px !important;
        }

        .react-datepicker__input-container {
            width: 110px;
        }

        .react-datepicker__current-month {
            display: block;
        }
    }

    .divider {
        width: calc(100% - 340px);
        color: $color-darkgrey;
        padding-top: 20px;
    }

    .participants {
        margin-top: -30px;
    }
}

.rc-time-picker-panel {
    z-index: 99999 !important;
}

li.rc-time-picker-panel-select-option-selected {
    background: #000 !important;
    color: #fff !important;
}

.rc-time-picker-panel-select li:hover {
    background: #d4dce6 !important;
}

.rc-time-picker-panel-clear-btn {
    display: none;
}

.rc-time-picker-panel-select:last-child li {
    display: none;
}

.rc-time-picker-panel-select:last-child li:first-child,
.rc-time-picker-panel-select:last-child li:nth-child(5n+6) {
    display: block;
}

.rc-time-picker-panel-select ul {
    max-height: 290px !important;
}


/* REPEATABLE EVENTS */
.repeatable-type {
    display: inline-block;
    margin-top: 10px;
    margin-bottom: 10px;
}

.repeatable {
    input[type=checkbox] {
        background-color: transparent !important;
    }

    span {
        margin-left: 5px;
        position: relative;
        top: -4px;
        font-size: 14px;
    }

    .badge span {
        top: 0px;
        margin-left: 0px;
    }
}

.repeatable-datepickers {
    margin-bottom: 20px;

    .date-time-section {
        .left {
            input {
                width: 110px;
            }
        }
    }
}

.Alerts {
    .Title {
        font-weight: 400;
        padding-bottom: 8px;
        font-size: 14px;
    }

    .AlertRow {
        display: flex;
        align-items: center;
        font-weight: 500;
        font-size: 14px;
        line-height: 12px;

        .AlertInput {
            border: none;
            line-height: 100%;
            font-size: 14px;
            height: 100%;
        }

        .InputWrapper {
            background-color: #FFF4E0;
            height: 28px;
            margin-right: 10px;
            max-width: 50px;
        }

        .DeleteButton {
            margin-left: 10px;
            width: 18px;
            height: 18px;
            background: $color-lightgrey;
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 50%;
        }
    }
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}