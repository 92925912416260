.Family {
    height: 100vh;
}

.FamilyTree {
    margin: 40px auto;
    max-width: $main-cards-with-tables-width;

    h2 {
        font-size: 20px;
        color: $color-grey3;
    }

    h3 {
        font-size: 18px;
        color: $color-grey3;
    }

  .MembersList {
      background-color: #f2f5f7;
      padding: 10px;
      border-radius: 5px;

      .members_list {
          list-style: none;
      }

      .list_header {
          justify-content: center;
          align-items: center;
          min-height: 44px;
      }

      .list_item + .list_item {
          margin-top: 10px;
      }

      .button-container {
          position: absolute;
          left: 0;
          top: 0;
      }
  }

  .MembersList + .MembersList {
      margin-top: 46px;
  }

  .family_container {
    width: 95%;
    min-width: 800px;
    max-width: $main-container-max-width;
    margin: 50px auto;

    .family_header {
        justify-content: flex-end;
    }
  }
}

.visibility-filters {
    background-color: $color-background;
    padding: 5px;
    border-radius: 3px;
}

.FamilyViewSwitcher {
    width: 50%;
    float: right;
    position: relative;
    top: 4px;
    padding-right: 10px;

    .link {
        color: #000 !important;

        &:hover {
            color: $color-main1 !important;
        }
    }

    i {
        font-size: 24px;
        margin-left: 10px;

        &.disabled {
            color: $color-grey2 !important;
        }
    }
}

.FamilyViewSwitcherContent {
    display: inline;
}

.FamilyMember {
    background-color: #fff;
    border: 0px solid transparent;
    display: flex;
    justify-content: space-between;
    padding: 10px 21px 10px 16px;
    box-shadow: 0 1px 4px 0 rgba(0, 0, 0, .1);
    outline: 0px solid #000;

    &:hover {
        outline-width: 1px;
        background-color: #f9f9f9;

        .status_container .remove .zmdi {
            display: flex;
        }
    }

    &.is_user {
        outline-color: $color-main1;
    }

    &.is_pending {
        outline-color: transparent !important;

        .status_container {
            .label {
                color: $color-orange;
                background-color: rgba(229, 153, 7, .2);
            }
        }
    }

    &.is_hidden {
        opacity: 0.4;
    }

    &.is_blocked {
        opacity: 0.6;

        &:hover {
            background-color: #fff;
            outline-color: #fff;
            box-shadow: 0 1px 4px 0 rgba(0, 0, 0, .1) !important;
    
            .status_container .remove .zmdi {
                display: none;
            }
        }
    }

    .avatar_container {
        align-items: center;

        .avatar {
            margin: 0;
            margin-right: 10px;
            border: 1px solid;
            border-radius: 50%;
        }
    }

    .status_container {
        align-items: center;

        .label {
            padding: 3px 10px;
            min-width: 170px;
            text-align: center;
            border-radius: 3px;
            color: $color-grey3;
            background-color: $color-lightgrey;
        }

        .remove {
            min-width: 35px;
            margin-left: 21px;
            justify-content: flex-end;

            .zmdi {
                display: none;
                cursor: pointer;
                color: #000;

                &:hover {
                    color: $color-main1;
                }

                &:last-child {
                    margin-left: 5px;
                }
            }
        }
    }

    .nickname {
        font-size: $font-size-base;
    }

    .Avatar {
      position: relative;
      width: 60px;
      height: 60px;

      img {
        position: absolute;
        left: 0;
      }
    }

    .avatar {
      position: relative;
      text-align: center;
      margin-bottom: 38px;

      img, .empty-image {
        display: inline-flex;
        width: 60px;
        height: 60px;
        border-radius: 50%;
      }

      .FileUploaderCustomButton {
        position: initial;
      }

      &.small {
        margin-bottom: 18px;

        img, .empty-image {
          width: 48px;
          height: 48px;
        }
      }

      &.not-active {
        opacity: 0.5;
      }
    }
}

.FamiliesMembersSectionTitles {
    display: flex;
    flex-direction: row;

    h3:first-child {
        width: 264px;
    }
}

.FamiliesMembers {
    display: flex;
    flex-direction: row;
    background-color: #fff;
    border: 0px solid transparent;
    padding: 14px 21px 11px 16px;
    box-shadow: 0 1px 4px 0 rgba(0, 0, 0, .1);
    outline: 0px solid #000;
    margin-bottom: 16px;
    overflow: auto;

    &:hover {
        outline-width: 1px;
        background-color: #f9f9f9;
    }

    .FamiliesMembersParents {
        display: flex;
        justify-content: space-around;
        width: 164px;
    }

    .FamiliesMembersKids {
        display: flex;
        justify-content: space-around;
        margin-left: 80px;
    }

    .avatar {
        margin: 0 15px 0 0;
        width: 60px;
        position: relative;
        height: 60px;
        border-radius: 30px;
        overflow: hidden;
        display: flex;

        &:not(.no-border) {
          border: 1px solid;
        }

        .empty-image {
          height: 60px;
          width: 60px;
        }

        img {
          width: 100%;
          height: auto;
        }
    }
}
