.Documents {

    .container {
        display: flex;
        flex-direction: row;
        height: calc(100vh - 72px);
        overflow: auto;

        .folders-container {
            width: 250px;
            background-color: #fff;
            height: calc(100vh - 72px);
            margin-top: 1px;
            padding: 20px 2px 0px;
            overflow: auto;

            h4 {
                padding-bottom: 10px;
                color: #516068;
                font-family: 'Quicksand', sans-serif;
                font-weight: 600;
                font-size: 14px;
                letter-spacing: 1.4px;
                text-transform: uppercase;
            }

            .folders-created {
                padding-left: 9px;
                padding-right: 9px;
            }

            .form__row {
                padding-left: 10px;
                padding-right: 10px;

                input[type="text"]:focus ~ label {
                    left: 10px;
                }

                input[type="text"]:focus ~ label, input[type="text"]:not([value=""]) ~ label, input[type="text"]:not([value]) ~ label {
                    left: 10px !important;
                }
            }

            .form__summary {
                padding-bottom: 20px;
            }

            .table {
                width: 100%;
                margin: 0 0 40px 0;
            }

            .tcell.folder-name {
                overflow: hidden;

                &:hover {
                    color: $color-main1;
                }

                .label {
                    text-overflow: ellipsis;
                    white-space: nowrap;
                    width: 60px;
                }
            }
        }

        .files-container {
            width: calc(100% - 250px);
            height: 100%;
            max-height: calc(100vh - 71px);
            overflow: auto;

            .files-list {
                width: 90%;
                margin: 0 auto;
                padding-left: 9px;
                padding-right: 9px;
                background-color: #fff;

                .table {
                    padding-top: 25px;
                    margin-top: 0px;
                }

                .notification-container {
                    text-align: center;
                    padding: 20px;
                }
            }
        }

    }
}
