* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  outline: none;
}

*, h1, h2, h3, h4, h5, h6 {
  margin: 0;
}

input {
  display: block;
  border: none;
}

button {
  background: none;
  border: none;
  cursor: pointer;
}

img {
  display: block;
}

a {
  text-decoration: none;
  color: inherit;
}
