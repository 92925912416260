.blank-layout {
  min-height: calc(100vh - 18px);
  height: 100%;

  .blank-layout__content {
    // flex: 1;
    // min-height: 100vh;
    // height: 100vh;
    display: flex;
    flex-direction: column;
  }
}
