.CompleteYourProfile {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  font-size: $font-size-base;
  padding-top: 21px;

  section {
    background-color: #fff;
    display: flex;
    flex-direction: column;
    padding: 46px 78px;
    width: 472px;

    @media (max-width: 1024px) {
      width: 100%;
    }

    @media (max-width: 420px) {
      padding: 40px;
    }
  }

  h2 {
    font-family: 'Quicksand', sans-serif;
    font-size: 24px;
    font-weight: 700;
    margin-bottom: 22px;
    text-align: center;
  }

  h2 + p {
    font-size: $font-size-base;
    line-height: 20px;
    width: 220px;
    text-align: center;
    margin: 0 auto 44px;
  }

  .form__row {
    position: relative;

    label {
      font-size: 12px;
      color: #B3B3B3;
      width: 108px;
      position: absolute;
      pointer-events: none;
    }

    input {
      font-size: 14px;
    }

    & + .notification {
      margin-top: 30px;
    }

    .react-datepicker__input-container {
      width: 100%;
    }

    @media (max-width: 420px) {
      margin-top: 32px;
    }
  }

  .button-container {
    margin-top: 17px;

    .RedirectButton {
      color: white;
      align-items: center;
      justify-content: center;
      text-decoration: none;
    }

    @media (max-width: 420px) {
      .button {
        width: 100%;
      }

      flex: 0.8;
    }
  }

  .title.inline-block.m-r-15 {
    min-width: 70px;
  }

  .avatar {
    position: relative;
    text-align: center;
    margin-bottom: 38px;

    img, .empty-image {
      display: inline-flex;
      width: 60px;
      height: 60px;
      border-radius: 50%;
    }

    .FileUploaderCustomButton {
      position: initial;
    }

    &.small {
      margin-bottom: 18px;

      img, .empty-image {
        width: 48px;
        height: 48px;
      }
    }

    &.not-active {
      opacity: 0.5;
    }

    @media (max-width: 1024px) {
      display: none;
    }
  }

  &.PaymentSection {
    padding: 46px 0;
    background-color: #fff;
    width: 472px;
    margin: 21px auto;

    .infoText {
    font-family: 'Quicksand', sans-serif;
      font-weight: 700;
      font-size: 12px;
      text-align: center;
      color: #a6a7a7;

      @media (max-width: 1024px) {
        font-size: 16px;
        width: 80%;
      }
    }

    .CreditCardContainer {
      width: 80%;
      margin: 0 auto 50px;

      img {
        width: 100%;
      }
    }

    .CardInputsWrapper {
      .CardElement {
        width: 100%;
      }

      .CardElement + .CardElement {
        margin-left: 10px;

        @media (max-width: 1024px) {
          margin-left: 0;
          margin-top: 20px;
        }
      }

      @media (max-width: 1024px) {
        flex-direction: column;
        padding: 0 40px;
      }

      @media (min-width: 1025px) {
        .CardElement + .CardElement {
          width: 30%;
        }
      }
    }

    .notificationContainer {
      min-height: 48px;

      .notification {
        span {
          text-align: center;
        }

        @media (max-width: 1024px) {
          margin: 0 20px;
        }
      }
    }

    .Title {
      font-family: Quicksand, sans-serif;
    }

    @media (max-width: 1024px) {
      width: auto;
      justify-content: space-between;
    }
  }
}

.react-datepicker__month-read-view--down-arrow,
.react-datepicker__year-read-view--down-arrow {
    position: relative !important;
    left: 4px !important;
    top: 5px !important;
}

.react-datepicker__portal {
  .react-datepicker__navigation--previous {
    border-right-color: transparent !important;
  }

  .react-datepicker__navigation--next {
    border-left-color: transparent !important;
  }
}

.CardDetailsWrapper {
  display: flex;
  justify-content: flex-start;

  .StripeCardElement {
    width: 20%;
  }
}

.StripeCardElement {
  padding-bottom: 5px;
  margin-bottom: 12px;
  border-bottom: 1px solid #C0BFC0;
}

.StripeCardElement + .StripeCardElement {
  margin-left: 24px;
}