.card {
    width: 100%;
    margin: 0px auto 30px;

    .header {
        padding: 18px 0 20px;
        text-align: center;

        .card-actions {
            opacity: 0.5;
            position: absolute;
            top: 19px;
            right: 9px;
        }

        span {
            font-family: 'Quicksand-Bold', sans-serif;
            font-weight: 700;
            font-size: 14px;
            text-transform: uppercase;
            color: #516068;
            letter-spacing: 1.4px;
        }
    }

    &:not(.leaf) {
        padding: 0px 9px 9px;

        &:hover {
            background-color: #E0E6EB;

            .header .card-actions {
                opacity: 1;
            }
        }
    }

    .card-content {
        padding: 33px;
        display: flex;
        justify-content: flex-start;
        flex-direction: row;
        flex-wrap: wrap;
        background-color: #fff;
        min-height: 204px;
    }

    &.empty {
        height: 74px;
        padding: 24px;
        text-align: center;
        background-color: #DDE3E8;
        color: #516068;
        border: 1px solid transparent;
        text-transform: uppercase;
        letter-spacing: 1.4px;
        font-family: 'Quicksand-Bold', sans-serif;
        font-weight: 700;

        .zmdi {
            font-size: 15px;
            font-weight: 700;
            position: relative;
            top: 1px;
        }

        &:not(.leaf):hover {
            color: $color-main1;
            border: 1px solid $color-main1;
        }
    }

    &.leaf {
        width: 226px;
        height: 134px;
        margin: 0 10px 20px;

        &:first-child, &:nth-child(4n+1) {
            margin-left: 0px;
        }

        &:nth-child(4n) {
            margin-right: 0px;
        }

        &.empty {
            padding: 18px;
            // margin: 0px auto;
            text-align: center;
            background-color: transparent;

            .button-container {
                position: relative;
                top: 50%;
                transform: translateY(-50%);

                button {
                    min-width: 226px;
                }
            }
        }

        .content {
            border: 1px solid #ececec;
            padding: 16px;
            font-size: $font-size-base;
            height: 100%;

            .title {
                font-weight: 300;
                font-family: 'Nunito', sans-serif;
                font-size: 16px;
                color: #000;
                line-height: 20px;
                width: 142px;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
            }

            .description-container {
                margin-bottom: 10px;
                overflow: auto;
                height: calc(100% - 10px);
            }

            .description {
                margin-top: 10px;
                font-size: 15px;
                color: #9F9F9F;
                line-height: 18px;
                white-space: pre-line;
            }

            &:hover {
                border: 1px solid #000;
                background-color: rgba(224, 224, 224, 0.2);

                .title {
                    font-weight: 600;
                    font-family: 'Nunito', sans-serif;
                }
            }
        }

        .actions {
            visibility: hidden;

            &:hover {
              visibility: visible !important;
            }
        }

        &:hover {
            .actions {
                visibility: visible;
            }
        }
    }

    &.no-leafs {
        .card-content {
            min-height: 132px;
        }

        .leaf {
            width: 100%;
            height: 66px;
        }
    }
}
