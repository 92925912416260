.button-container {
  &.button-container--align-center {
    display: flex;
    justify-content: center;
  }

  &.button-container--align-right {
    display: flex;
    justify-content: flex-end;
  }
}

.button {
  $height: 40px;
  font-family: 'Quicksand', sans-serif;
  font-size: 13px;
  font-weight: 600;
  letter-spacing: 1.4px;
  color: #ffffff;
  background: $color-main1;
  height: $height;
  line-height: $height - 2;
  text-align: center;
  border-radius: ($height / 2);
  text-transform: uppercase;
  white-space: nowrap;
  padding: 0 30px;
  min-width: 144px;
  transition: .2s;

  &:hover {
    background: $color_main1_hover;
  }

  &[disabled] {
    cursor: not-allowed;
    opacity: 0.4;
  }

  &.clean {
    background-color: transparent;
    border: 1px solid $color-main1;
    color: $color-main1;

    &:hover {
      color: #fff;
      background: $color_main1_hover;
    }
  }

  &.small {
    font-size: 15px;
    height: 28px;
    line-height: 15px;
    width: 120px;
    text-transform: initial;
    letter-spacing: 0px;
    width: 120px;
    font-family: 'Nunito', sans-serif;
    font-weight: 300;
    padding: 0px;
  }

  &.link {
      background-color: transparent;
      font-size: 16px;
      font-weight: 400;
      text-transform: initial;
      padding: 0;
      min-width: 0;

      &:hover {
          text-decoration: underline;
      }
  }

  &.circle {
      width: 60px;
      height: 60px;
      border-radius: 50%;
      min-width: 0px;
      font-size: 42px;
      padding: 0 0 4px;
      background-color: rgba(229, 153, 7, 0.08);
      border: 2px solid $color-main1;
      color: $color-main1;

      &:hover {
        background-color: $color-main1;
        color: #fff;
      }
  }

  &.delete {
    color: $color-grey2;
    height: auto;
    line-height: 12px;
    text-transform: uppercase;
    font-size: 12px;
  }
}
