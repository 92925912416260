.CropperModal {
  max-width: 900px;
  background-color: #FFFFFF;
  border-radius: 10px;
  position: absolute;
  left: calc(50% - 450px);
  top: 100px;
  z-index: 1000000;

  .CropperModalBackground {
    background-color: rgba(0,0,0, 0.4) !important;
    width: 100vw;
    height: 100vh;
    position: fixed;
    left: 0;
    top: 0;
    z-index: 0;
  }

  .CloseIconContainer {
    height: 30px;
    width: 30px;
    position: absolute;
    right: 10px;
    top: 10px;
    cursor: pointer;


    .CloseIcon {
      position: absolute;
      width: 3px;
      height: 30px;
      background-color: #000;
      transform: rotate(-45deg);
      opacity: 0.6;
      right: 15px;

      &.RightIcon {
        transform: rotate(45deg);
      }
    }
  }

  &Container {
    flex: 1;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .ImageContainer {
    width: 100%;

    display: flex;
    align-items: center;
    justify-content: center;
  }

  .ButtonContainer {
    display: flex;
    justify-content: center;
    margin-top: 30px;

    .UploadButton {
      padding: 10px 20px;
      outline: 0;
      border-radius: 5px;
      transition: .2s;
      font-size: 14px;
      background-color: dodgerblue;
      color: #fff;
      cursor: pointer;

      &:hover {
        opacity: .9;
      }

      &:focus {
        outline: 0;
      }
    }
  }
}
