.form {
  .form__row {
    & + .form__row {
      margin-top: 32px;
    }

    .react-datepicker-wrapper {
      width: 100%;
    }

    input {
      border-bottom: 1px solid #B3B3B3;
      height: 24px;
      line-height: 24px;
      font-size: 16px;
      font-weight: 300;
      width: 100%;
      text-align: center;
      background-color: transparent;
      font-family: 'Nunito', sans-serif;
      outline: none;

      &[type="text"][value=""]:not(.error),
      &[type="number"][value=""]:not(.error),
      &[type="email"][value=""]:not(.error),
      &[type="password"][value=""]:not(.error) {
        border-bottom-color: #b3b3b3;
      }

      &[type="email"][value=""]:not(.error):disabled {
        border-bottom-color: $color-lightgrey;
      }

      &:placeholder-shown {
        border-bottom-color: #000;
      }

      &.error {
        border-bottom-color: $color-error !important;
        color: #000 !important;
      }

      &:focus {
        border-bottom-color: $color-main1;
      }

      &:disabled {
        border-bottom-color: $color-lightgrey;
      }

      @include placeholder {
        color: $color-text-input-placeholder;
        text-transform: lowercase;
      }
    }

    input[type='radio'], input[type='checkbox'] {
      background-color: $color-grey;
      width: 16px;
      background-color: transparent;
    }

    input[type="text"] ~ label,
    input[type="number"] ~ label,
    input[type="password"] ~ label,
    input[type="email"] ~ label {
      position: absolute;
      top: 3px;
      left: 50%;
      transform: translateX(-50%);
      font-family: 'Nunito-Light', sans-serif;
      font-size: 14px;
      color: #b3b3b3;
      pointer-events: none;
      transition: 0.2s ease all;
      text-align: center;
      text-transform: lowercase;
    }

    input[type="text"] ~ label.amountLabel {
      left: initial;
    }

    input[type="text"]:focus ~ label, input[type="text"]:not([value=""]) ~ label, input[type="text"]:not([value]) ~ label,
    input[type="number"]:focus ~ label, input[type="number"]:not([value=""]) ~ label, input[type="number"]:not([value]) ~ label,
    input[type="password"]:focus ~ label, input[type="password"]:not([value=""]) ~ label, input[type="password"]:not([value]) ~ label,
    input[type="email"]:focus ~ label, input[type="email"]:not([value=""]) ~ label, input[type="email"]:not([value]) ~ label {
      left: 0px;
      top: 5px;
      font-size: 12px;
      transform: none;
      text-align: left;
    }

    input[type="text"]:not([value=""]) ~ label,
    input[type="number"]:not([value=""]) ~ label,
    input[type="password"]:not([value=""]) ~ label,
    input[type="email"]:not([value=""]) ~ label {
      color: transparent;
    }

    input[type="text"]:not([value=""]):focus ~ label,
    input[type="number"]:not([value=""]):focus ~ label,
    input[type="password"]:not([value=""]):focus ~ label,
    input[type="email"]:not([value=""]):focus ~ label {
      color: $color-main1;
    }

    input.error ~ label {
      color: $color-error;
    }

    input ~ label ~ .notification--error {
      font-size: 13px;
    }

    label.edge {
      color: #b3b3b3 !important;
    }

    .textarea-container {
      position: relative;
      height: 74px;
      background: #FEF9F1;
      padding: 17px;

      textarea {
        width: 100%;
        height: 100%;
        background: #FEF9F1;
        border: none;
        resize: none;
        font-size: $font-size-base;
        font-family: 'Nunito-Light', sans-serif;

        &.textarea-note::-webkit-input-placeholder {
          padding: 10px;
          text-align: center;
          color: $color-main1;
          font-size: $font-size-base;
        }

        &.textarea-note::-moz-input-placeholder {
          padding: 10px;
          text-align: center;
          color: $color-main1;
          font-size: $font-size-base;
        }

        &.textarea-note:-moz-input-placeholder {
          padding: 10px;
          text-align: center;
          color: $color-main1;
          font-size: $font-size-base;
        }

        &.textarea-note:-ms-input-placeholder {
          padding: 10px;
          text-align: center;
          color: $color-main1;
          font-size: $font-size-base;
        }
      }
    }

    &.radios {
      margin-bottom: -10px;

      label {
        margin-left: 10px;
        margin-top: 2px;
        font-size: $font-size-base;
      }
    }

    .infoText {
      font-size: 15px;
    }

    .hint {
      position: absolute;
      right: 0;
      bottom: 3px;
      top: 0px;

      span:first-of-type {
        color: $color-text-input-placeholder;
        font-size: 11px;
        background-color: $color-lightgrey-badge;
        border-radius: 50%;
        width: 18px;
        height: 18px;
        line-height: 18px;
        display: inline-block;
        text-align: center;
        cursor: pointer;
      }

      span:last-of-type {
        display: block;
        color: #182228;
        background-color: #fff;
        font-size: 11px;
        padding: 8px 13px;
        width: 320px;
        height: 0px;
        position: absolute;
        left: -300px;
        top: 33px;
        border-radius: 2px;
        z-index: -1;
        line-height: 15px;
        border: 1px solid $color-table-border;
        transition: all 0.3s ease-in-out;
        opacity: 0;

        &:before {
          content: '';
          display: block;
          position: absolute;
          left: 301px;
          top: -7px;
          width: 12px;
          height: 12px;
          transform: rotate(-315deg);
          background-color: #fff;
          border: 1px solid $color-table-border;
          border-bottom: none;
          border-right: none;
          z-index: 9;
        }
      }

      &:hover {
        span:last-of-type {
          opacity: 1;
          height: auto;
          transition: all 0.3s ease-in-out;
          z-index: 9;
        }
      }
    }

    .CustomSelect {
      position: relative;

      .Label {
        color: $color-grey3;
        font-size: 14px;
      }

      .css-vj8t7z {
        border: none !important;
      }

      .css-d8oujb {
        display: none;
      }

      .css-2o5izw {
        border: none !important;
        box-shadow: none;
      }

      .css-15k3avv {
        border-radius: 0;
        top: 56px;
      }

      .css-1hwfws3 {
        justify-content: center;
        margin-left: 36px;
        font-size: 14px;
      }
    }
  }

  .form__summary {
    font-size: 12px;
    text-align: center;
    padding-left: 40px;
    padding-right: 40px;
    margin-top: 20px;

    .button {
      margin-bottom: 20px;

      &:first-child {
        margin-top: 32px;
      }
    }

    .cancel {
      color: #b3b3b3;
      text-decoration: underline;
      font-size: 14px;
      cursor: pointer;
    }

    .error-container.hide {
      display: block;
      opacity: 0;
    }
  }

  .form__field-info {
    font-size: 12px;
    text-align: center;
    line-height: 24px;
    position: absolute;
    width: 100%;
  }

  &.disabledCalendarForm {
    height: 100%;
    display: flex;
    justify-content: center;
    flex-direction: column;
  }
}
