$font-size-base: 15px;

$color-text-base: #000000;
$color-text-heading: #221E20;
$color-text-input-placeholder: #B3B3B3;
$color-main1: #E59907;
$color-main1-hover: #D68A00;
$color-error: #F15C50;
$color-grey: #d7d9da;
$color-grey2: #a6acaf;
$color-grey3: #8a8a8a;
$color-grey4: #2e383e;
$color-lightgrey: #EBEEEF;
$color-background: #f2f5f7;
$color-lightgrey2: #f4f6f8;
$color-lightgrey-badge: #F4F6F8;
$color-table-border: #ececec;
$color-table-header-border: #e0e0e0;
$color-olive: #a6aa59;
$color-orange: #f5754e;
$color-lightblue: #81bbbd;
$color-darkgrey: #182228;
$color-green: #60991D;
$color-red: #EF481C;
$color-yellow: #fbd850;
$color-white: #ffffff;


$color-header-border: #d4d4d4;
$color-info: #000;
$color-success: #629729;
$color-error: #EF481C;

$header-height: 72px;
$aside-width: 215px;
$aside-small-width: 72px;
$main-container-min-width: 1260px;
$main-container-max-width: 1260px;

$main-cards-with-tables-width: 1058px;
