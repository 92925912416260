.badge {
    padding: 0px 12px !important;
    border-radius: 43px !important;
    display: inline-block !important;
    margin-right: 10px;
    margin-bottom: 5px;
    background-color: #E0E6EB;
    color: #000;
    cursor: pointer;
    height: 26px !important;
    line-height: 22px !important;

    &[rel='animated-color'] {
        transition: all 0.2s ease-in;
    }

    &.empty {
        width: 16px !important;
        height: 16px !important;
        padding: 0px !important;
    }

    &:hover {
        opacity: 0.8;
    }

    &.active {
        transition: all 0.2s ease-in;
        background-color: $color-main1;
    }

    &:not(.active):hover {
        background-color: #D4DCE3;
    }

    &.button {
        padding-left: 14px !important;
    }

    span {
        font-family: 'Nunito', sans-serif;
        font-size: 15px;
        font-weight: 300;
        position: relative;
        top: 1px;
        -webkit-user-select: none; /* webkit (safari, chrome) browsers */
        -moz-user-select: none; /* mozilla browsers */
        -khtml-user-select: none; /* webkit (konqueror) browsers */
        -ms-user-select: none; /* IE10+ */
    }

    .history-filters & {
        height: 20px !important;
        line-height: 17px !important;

        span {
            font-size: 12px !important;
        }
    }

    .visibility-filters & {
        height: 22px !important;
        line-height: 18px !important;

        span {
            font-size: 13px !important;
        }
    }

    &.circle {
        width: 26px;

        span {
            font-size: 18px;
            top: 3px;
            left: -1px;
        }
    }
}

.ReactModal__Content {
    .badge {
        background-color: $color-lightgrey-badge;
    }
}
